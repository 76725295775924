.navBar {
  margin-top: 0;
  background-color: #AFC2D5;
  margin-bottom: 0;
}

.heading {
  display: flex;
  flex-direction: row;
}

.userInfo {
  text-align: right;
}

.userInfo h4 {
  display: inline-block;  
}

.heading button {
  width: 100px;
  background-color: #535355;
  border: none;
  border-radius: 8px;
  color: #F1FFFA;
}

.heading button:hover {
  /* background-color: rgba (90, 90, 91, 0.2); */
  opacity: 0.8;
  cursor: pointer;
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.links>a {
  text-decoration: none;
  padding: 10px;
  padding-bottom:5px;
  color: black;
}

nav ul {
  /* display: flex; */
  /* flex-direction: column; */
  list-style: none;
  justify-content: flex-start;
}

.tab-page {
  background-color: #8E745E;
}

.tab-page h2 {
  text-align: left;
}

.collapsible {
  background-color: #6CAE75;
}