.collapsible {
  background-color: #6CAE75;
  ;
  /* display: flex;
  flex-direction: row; */
  color: #444;
  cursor: pointer;
  width: 100%;
  border: black 2px;
  text-align: left;
  outline: none;
  font-size: 15px;
  max-height: 9000px;
  transition: all 300ms ease-in-out;
}

.collapsibleInner {
  padding: 18px;
  display:flex;
  flex-direction: column;
}

.collapsible.hide {
  max-height: 0px;
  display: block!important;
  overflow: hidden;
  padding: 0;
}

.collapsible em {
  padding-left: 10px;
  text-align: right;
  padding-right: 0;
  float: right;
  font-size: 15px;
}
.links>.link{
  margin-bottom:.5em;
  line-height:1.2;
}
.links>.link.active  {
  border-bottom: .2em solid black;
}
.collapsible:hover{
  opacity: 0.8;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}

.button-content {
  max-height: 0px;
  padding: 0px !important;
  overflow: hidden;
  background-color: #f1f1f1;
  transition: all 300ms ease-in-out;
}

.button-content.expanded {
  max-height: 9000px;
  overflow: auto;
}


.button-content button {
  background-color: #535355;
  border: none;
  border-radius: 8px;
  color: #F1FFFA;
  width: 80px;
}

.editMode-buttons {
  display: none;
}

.editMode-buttons.show {
  display: block;
}
.details form{ 
  /* display:flex;
  flex-direction: column;
  justify-content: space-between;  */
  margin-top:20px;
  clear:both;
}
.details p{
  font-size:14px;
  margin-bottom: 5px;
}
.details span{
  margin-bottom: 5px;
  margin-left:5px;
  font-size: 16px;
  font-weight: 700;
}
.details input, .details textarea{
  display: block;
	font-size: 16px;
	font-family: sans-serif;
	color: #444;
	line-height: 1.3;
	padding: 0.4em 1.4em 0.4em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: 4px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
