.user-buttons {
	margin-top: 10px;
	display: flex;
	margin-bottom: 10px;
	margin-left: 10px;
}

.user-buttons button {
	background-color: #535355;
	border: none;
	border-radius: 8px;
	color: #F1FFFA;
	width: 80px;
	margin: 5px;
}




.searchBar button {
	background-color: #535355;
	border: none;
	border-radius: 8px;
	color: #F1FFFA;
}

.tab-navBar form {
	display: flex;
	flex-direction: column;
}

.sortButtons, .pageButtons, .dateFilter {
	margin-bottom: 1em;
	/* margin-top:.75em; */
}

.buttonsRowLabel {
	display: block;
	margin-top: .75em;
	margin-bottom: .5em;
}

.buttonsRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	/* compensate for button margins */
	margin-left: -.3em;
}

.buttonsRow>button, .pageButtons>span {
	flex: 1;
	text-align: center;
}

.btnPrev svg {
	float:left;
	margin-left:.5em;
}

.btnNext svg  {
	float:right;
	margin-right:.5em;
}

.buttonsRow button {
	margin: 5px, 10px, 5px, 0px;
}

.tab-navBar button {
	background-color: #535355;
	border: none;
	border-radius: 8px;
	color: #F1FFFA;
	width: 80px;
}

.tab-navBar button:hover {
	opacity: 0.8;
	cursor: pointer;
}

.searchBar {
	clear: both;
}
.search-keyword{
	display:flex;
}
.search-keyword{
	margin-top: 5px;
	display: block;
	font-size: 16px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .0em 1.4em .0em .8em;
	width: 80%;
	max-width:60vw;
	box-sizing: border-box;
	margin: 0;
}
.searchSubmitReset {
	display:flex;
	flex-direction:row;
	
}
.searchSubmitReset input{
	background-color: #535355;
	border: none;
	border-radius: 8px;
	color: #F1FFFA;
	margin:	0 .3em 0 .3em;
	flex: 1;
}
.barTitle {
	float: left;
	padding-top: 0;
}

.addButton {
	background-color: #535355;
	border: none;
	border-radius: 8px;
	color: #F1FFFA;
	/* width: 80px; */
	margin-top: 5px;
	margin-bottom: 5px;
	padding: .6em;
	float: right;
}

.sortResults {
	margin-top: 5px;
	display: block;
	font-size: 16px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .0em 1.4em .0em .8em;
	width: 100%;
	max-width:80vw;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
	border-radius: 4px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

.search-word-bar {
	margin-bottom: 5px;
}

.search-keyword input {
	display: flex;
	font-size: 16px;
	font-family: sans-serif;
	font-weight: 700;
	line-height: 1.3;
	padding: 0em 1em 0em .8em;
	width: 60%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
	border-radius: 4px;
	
}

.search-word-bar {
	display: flex;
}

.dates input {
	display: block;
	font-size: 12px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: 0em 1em 0em .8em;
	width: 90%;
	max-width: 60vw;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
	border-radius: 4px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

.dates {
	display: flex;
	margin-bottom: 5px;
}
.submitProjectFilters{
	width:70px;
}
.sortResults:hover {
	border-color: #888;
}

.sortResults:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}

.pageButtons {
	display: flex;
	justify-content: space-evenly;
}

.pageButtons button {
	width: 60px;
}

@media only screen and (min-width: 475px) {
	
}