.LoginPage-Container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  color:white;
}

.LoginPage {
  display: flex;
  flex-direction: column;
  margin: auto;
  /* height: 75vh; */
  /* width:75%; */
  max-width: 400px;
  margin:1em;

  background: rgba(0,0,0, 0.5);
  border-radius: .5em;

}

.LoginPage p {
 margin-top: 10px;
  line-height:140%;
  text-align: center;
  
}

h2 {
  text-align: center;
  font-size:1.5em;
  margin-top: 10px;
}

.LoginForm {
  padding-left: 5px;
  padding-right: 5px;
}

.LoginForm form {
  margin: auto;
}

.LoginForm label {
  font-size: 18px;
}

.LoginForm input {
  margin-bottom: 10px;
  width: 100%;
}

.LoginForm button {
  display: block;
  margin: auto;
  width: 30%;
  display: block;
  background-color: #6CAE75;
  margin: auto;
  font-size: 18px;
  border-radius: 30px;
  border:none;
}

.LoginForm button:hover {
  background-color: #F1FFFA;
}