* {
  box-sizing: border-box;
  margin: 0;
}

html {
  background-image: url("../../images/Portland-background.jpeg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}



.App {
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
}

.appInfo {
  justify-items: left;
}

.appInfo h3 {
  display: inline-block;
}

.logo {
  background-image: url("../../images/lion.png");
  height: 15vmin;
  width: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
}

.logo.small {
  display: inline-block;
  height: 6vmin;
  width: 6vmin;
  margin-right: 10px;
}

/* global styles */

button {
  padding: 0.3em;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flexed {
  flex: 1;
}

.padded {
  padding: 1em;
}

.padded-bottom {
  padding-bottom: 1em;
}

.padded-top {
  padding-top: 1em;
}

.l-spaced {
  margin-left: 1em;
}

.r-spaced {
  margin-right: 1em;
}

.h-spaced {
  margin-left: 1em;
  margin-right: 1em;
}

.hide {
  display: none;
}

/* these are reuseable across edit forms */

.tab-content section {
  border-bottom: 1px solid lightslategray;
}

.actionButtonsWrapper {
  display: flex;
  align-items: center;
  float: right;
  padding-left: 1em;
}

.actionButton {
  margin-left: 0.5em;
}

.closeButton {
  color: teal;
  /* padding:.3em; */
  font-size: 20px;
  margin-left: 0.5em;
}

.buttonsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonsRow button {
  margin: 0 0.3em 0 0.3em;
}